@use 'ui-kit/theme/units' as u;

.root {
  display: flex;
  gap: u.spacing(5);
  padding-left: 10px;
}

.navItemText {
  font-size: 16px;
  font-weight: normal;
  &:hover {
    text-decoration: none;
  }
}

.navItemPromo {
}

.menuAlign {
  height: 100%;
}

.cityMenuWrapper {
  display: flex;
  align-items: center;
}

.menuClass {
  border: 1px solid var(--c-gray400);
  border-radius: 8px;
}
