@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.searchContainer {
  width: 100%;
  max-width: none;
  @include mq.forTabletLandscape {
    max-width: 377px;
  }
  @include mq.forUpToTablet {
    margin-top: u.spacing(3);
  }
}

.newSearchContainer {
  width: 100%;
  margin-top: u.spacing(4);
  @include mq.forTabletLandscape {
    max-width: 377px;
    width: calc(100% - 48px);
    bottom: -24px;
    left: 24px;
    right: 24px;
  }
  @include mq.forPhone {
    position: absolute;
    bottom: -24px;
    left: 24px;
    width: calc(100% - 48px);
    right: 24px;
    margin-top: u.spacing(3);
  }
}

.fixedSearchContainer {
  position: fixed;
  bottom: 0;
  z-index: 9;
  padding-bottom: u.spacing(2);
  @include mq.forTabletLandscape {
    max-width: unset;
  }
}

.isFluid {
  max-width: 100% !important;
  width: 100% !important;
}

.isResetPlacement {
  position: relative;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  margin: 0 !important;
  padding: 0 !important;
  @include mq.forPhone {
    position: relative;
  }
}
