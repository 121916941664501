@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  // If you've come here to change display to inline-flex - go away and override it via className ;D
  // inline-flex leads to some hard-to-reproduce bugs related to item width, so double check that everything works well
  // We have problems with mobile Chrome on iOS
  display: flex;

  align-items: center;
  background-color: var(--c-white);
  padding: u.spacing(0.5, 1);
  border: 1px solid var(--c-gray500);
  border-radius: 8px;
  box-sizing: border-box;
  @include mq.forUpToTablet {
    padding: u.spacing(0.5, 1);
    border-width: 1px;
  }
  & > * {
    flex-shrink: 0;
  }
}

.mobileInline {
  padding: 0;
  border-width: 0;
}
