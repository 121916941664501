@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
}

.resultsList {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.locationTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--c-gray800);
  margin-top: 0;
  margin-bottom: u.spacing(1);
  margin-left: u.spacing(1);
}

.locationSuggestion {
  padding: u.spacing(1);
  color: var(--c-primaryBlue);
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: capitalize;
  &:global(.Mui-focused),
  &:global(.Mui-focusVisible) {
    background-color: var(--c-gray400);
  }
}

.locationGroupDivider {
  margin-bottom: u.spacing(3);
  padding: u.spacing(0, 3);
}

.recentSearch {
  margin-bottom: u.spacing(3);
}

/**
  Quick fix for CLS
  Cities list is being loaded lazily and it shifts the content after loading
  It's hard to fix the bug properly without changing the layout and components composition
  so for now I decided to just add some big enough min-height and fix it properly later
 */
.layoutShiftFixWrapper {
  // the city list is pretty big and it's always bigger than 100vh on mobile
  min-height: 100vh;
  @include mq.forDesktopSmallUp() {
    min-height: 700px;
  }
}
