.link {
  align-items: center;
  display: inline-flex;
  cursor: pointer !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.linkUnderlined {
  text-decoration: underline;
}

.disabledLook {
  cursor: default !important;
  color: var(--c-gray800) !important;
  text-decoration: none !important;
  font-weight: normal !important;
}
