@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  flex-direction: column;
  gap: u.spacing(3);
  @include mq.forDesktopSmallUp {
    padding: u.spacing(3);
  }
}

.article {
  background-color: var(--c-neutralLightBlue);
  width: 100%;
  border-radius: 8px;
}

.isPrimaryArticle {
  background-color: var(--c-pendingLightYellow);
}

.ctaContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: u.spacing(2);
}

.subMenuItem {
  display: grid;
  gap: u.spacing(2);
  grid-template-columns: 90px auto;
  @include mq.forDesktopSmallUp {
    grid-template-columns: 110px auto;
  }
}

.postWrapper {
  @include mq.forUpToTablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: u.spacing(3);
  }
}

.postItem {
  display: grid;
  gap: u.spacing(2);
  grid-template-columns: 1fr;
  @include mq.forDesktopSmallUp {
    grid-template-columns: 110px auto;
  }
}
