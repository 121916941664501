.root {
  width: var(--avatar-size);
  height: var(--avatar-size);
  font-size: calc(var(--avatar-size) / 2);
  background-color: var(--avatar-color);
  border: 2px solid var(--avatar-error-color);
}

.invalid {
  & img {
    display: none;
  }
}
