@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.buttonText {
  margin-left: u.spacing(1);
}

.subTitle {
  margin-top: u.spacing(2);
  @include mq.forUpToTablet {
    margin-top: u.spacing(1);
  }
}

.button {
  padding-left: u.spacing(0);
}
