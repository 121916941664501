@use 'ui-kit/theme/units' as u;

.isFixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: u.spacing(2, 3);
  background: var(--c-white);
  border-top: 1px solid var(--c-gray500);
  z-index: 1;
}

.isEmbedded {
  display: flex;
  align-items: center;
  width: 100%;
}
