@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  gap: u.spacing(2);
  flex-wrap: nowrap;
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: auto;
  @include mq.forDesktopSmallUp {
    margin: u.spacing(0, 2);
  }
}

.wide {
  @include mq.forDesktopSmallUp {
    margin: u.spacing(0, 0, 0, 2);
  }
}

.mobileButton {
  width: 150px;
  height: 40px;
  font-size: 18px;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 40px;
  padding: u.spacing(1);
  background-color: var(--c-transparent);
  border-radius: 100px;
}

.avatar {
  border: 2px solid var(--c-gray300);
}

.flexWrap {
  display: flex;
  gap: u.spacing(2);
}

.signInWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.signUpButton {
  padding-left: 20px;
  padding-right: 20px;
}

.button {
  margin-right: u.spacing(2);
}

.arrow {
  margin-left: u.spacing(1);
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;
}

.rotateArrow {
  transform: rotate(-180deg);
}

.nextStepButton {
  max-height: 40px;
  width: 120px;
  min-width: max-content;
  margin-right: u.spacing(1);
  @include mq.forUpToTablet {
    margin-right: u.spacing(0);
  }
}

.hideMdDown {
  display: flex;
  @include mq.forUpToTablet {
    display: none;
  }
}

.hideMdUp {
  display: none;
  @include mq.forUpToTablet {
    display: flex;
  }
}
