@use 'ui-kit/theme/units' as u;

.root {
  display: flex;
  flex-direction: column;
  gap: u.spacing(1);
  padding: u.spacing(1);
}

.mainMenuItem {
  border-radius: 8px;
  padding: u.spacing(2, 3);
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  gap: u.spacing(1);
  min-width: 264px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  &:hover {
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
  }
}

.isMainMenuSelected {
  background-color: var(--c-primaryBlue);
  color: var(--c-white);
}
