@use 'ui-kit/theme/units' as u;

.stateWrapper {
  display: flex;
  align-items: center;
  flex-basis: 40%;
  justify-content: space-between;
  padding: u.spacing(2, 3);
  border-radius: 8px;
  position: relative;
}

.stateBackground {
  background-color: var(--c-primaryBlue);
  color: var(--c-white);
}

.stateRightIcon {
  position: absolute;
  right: 8px;
}

.mainContainer {
  display: flex;
  width: 592px;
  background-color: var(--c-white);
}

.content {
  white-space: normal;
}

.citiesSection {
  display: flex;
  padding: u.spacing(3);
  padding-right: u.spacing(2);
  flex-direction: column;
  gap: u.spacing(1);
  flex-basis: 60%;
  max-height: 80vh;
}

.alert {
  border: 1px solid var(--c-gray600);
  border-radius: 8px;
  background-color: var(--c-gray300);
  text-wrap: initial;
  white-space: initial;
}

.cityWrapper {
  height: 80vh;
}

.cityScrollbar {
  overscroll-behavior: contain;
  scrollbar-color: var(--c-gray400) var(--c-transparent);
  scrollbar-width: thin;
}

.cityLink {
  color: var(--c-black);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: u.spacing(2);
  align-items: center;
  gap: u.spacing(1);
  &:hover {
    background-color: var(--c-gray400);
    border-radius: 8px;
    font-weight: bold;
    color: var(--c-primaryBlue);
    text-decoration: none;
  }
}

.label {
  display: flex;
  padding: u.spacing(0.5, 2);
  align-items: center;
  gap: u.spacing(0.5);
  border: 1px solid var(--c-gray600);
  border-radius: 100px;
  background-color: var(--c-white);
}

.menuScrollArea {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  height: 80vh;
  flex-basis: 45%;
  padding: u.spacing(1);
  background-color: var(--c-gray400);
}

.menuScrollbar {
  overscroll-behavior: contain;
  scrollbar-color: var(--c-gray600) var(--c-transparent);
  scrollbar-width: thin;
}

.menuClass {
  background-color: var(--c-gray400);
  border: 1px solid var(--c-gray400);
  border-radius: 8px;
}
