@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--c-white);
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--c-gray600);
}

.topContent {
  position: relative;
  background-color: var(--c-gray500);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 1 1 auto;
  aspect-ratio: 1.39;
  width: 100%;
}

.bottomContent {
  height: 100%;
  box-sizing: border-box;
  padding: u.spacing(2);
  flex: 1 1 auto;
}

.compactBottomContent {
  display: none;
  flex: 1 1 auto;
}

.compact {
  @include mq.forUpToTablet {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    & .topContent {
      padding-top: 0;
      width: 30%;
      aspect-ratio: 1;
    }
    & .bottomContent {
      overflow: hidden;
      white-space: nowrap;
      flex: 0 0 auto;
      width: 70%;
      padding: 12px;
    }
    & .compactBottomContent {
      display: flex;
    }
  }
}

.withoutShadow {
  box-shadow: initial;
}

.fluid {
  height: 100%;
}

.highlighted {
  border: 1px solid var(--c-primaryBlue);
}
