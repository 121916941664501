@use 'ui-kit/theme/units' as u;

.root {
  display: flex;
  border-top: 1px solid var(--c-gray600);
  margin-top: u.spacing(2);
  padding-block: u.spacing(1);
}

.smallVariant {
  margin-top: u.spacing(1);
  padding-block: u.spacing(0.5);
}

.icon {
  margin-right: u.spacing(1);
  display: flex;
  align-items: center;
}

.time {
  display: inline-block;
}
