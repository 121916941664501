@use 'ui-kit/theme/units' as u;

.root {
  margin: 0;
  padding: u.spacing(3);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.unboxed {
  padding: 0;
  border: none;
  background: none !important;
}

.warning {
  background: var(--c-inactiveLightRed);
  border-color: var(--c-inactiveRed);
}

.info {
  background: var(--c-pendingLightYellow);
  border-color: var(--c-pendingYellow);
}

.confirmation {
  background: var(--c-activeLightGreen);
  border-color: var(--c-activeGreen);
}

.tip {
  background: var(--c-gray300);
  border-color: var(--c-gray600);
}

.reminder {
  background: var(--c-pendingLightYellow);
  border-color: var(--c-primaryYellow);
}

.wideText {
  margin-left: -4px;
}

.ctaButtonWrapper {
  margin-top: u.spacing(2);
}

.ctaButtonWrapperFullWidth {
  width: 100%;
}

.ctaButton {
  background-color: var(--c-white);
}

.iconAbove {
  margin-bottom: u.spacing(1);
}
