@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.alertIconBoxRoot {
  display: flex;
  align-items: flex-start;
  gap: u.spacing(2);
  padding: u.spacing(3, 2);
  border: 1px solid var(--c-gray600);
  border-radius: 8px;
  text-align: left;
  background-color: var(--c-gray300);
  @include mq.forPhone {
    flex-direction: column;
    gap: 0;
  }
}

.headText {
  @include mq.forPhone {
    margin-bottom: u.spacing(1);
  }
}
