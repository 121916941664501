@use 'ui-kit/theme/units' as u;

.paper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: u.spacing(1.5);
  background-color: var(--c-white);
  border: 1px solid var(--c-gray500);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
}

.image {
  border-radius: 100px;
}

.truncate {
  max-width: 148px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
