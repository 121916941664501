@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

$DEFAULT_APP_BAR_HEIGHT: 72px;
$SMALL_APP_BAR_HEIGHT: 62px;

.logo {
  margin-right: u.spacing(3.75);
}

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:has(.hostLabel) .logo {
    margin-right: u.spacing(2);
  }
}

.centerContent {
  display: flex;
  height: $SMALL_APP_BAR_HEIGHT;
  margin-left: 0;
  margin-right: auto;

  @include mq.forDesktopSmallUp {
    height: $DEFAULT_APP_BAR_HEIGHT;
  }
}

.menuButton {
  margin-left: u.spacing(1);
  margin-right: auto;
}

.hostLabel {
  display: flex;
  border-left: 1px solid var(--c-gray400);
  padding-left: u.spacing(2);
  margin-right: u.spacing(3.75);
}

.hideLgDown {
  display: flex;
  @include mq.forUpToTabletLandscape {
    display: none;
  }
}

.hideMdDown {
  display: flex;
  @include mq.forUpToTablet {
    display: none;
  }
}

.hideLgUp {
  display: none;
  @include mq.forUpToTabletLandscape {
    display: flex;
  }
}

.hideMdUp {
  display: none;
  @include mq.forUpToTablet {
    display: flex;
  }
}
