@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  border-radius: 8px;
  display: flex;
  min-height: 729px;
}

.mainNavigationWrapper {
  background-color: var(--c-gray400);
  @include mq.forDesktopSmallUp {
    max-width: 340px;
  }
}

.subMenu {
  padding: u.spacing(3);
  display: flex;
  flex-direction: column;
  gap: u.spacing(1);
  @include mq.forDesktopSmallUp {
    min-width: 340px;
  }
  @include mq.forDesktopMidUp {
    min-width: 500px;
  }
}

.rightSideBar {
  @include mq.forDesktopSmallUp {
    min-width: 360px;
  }
  @include mq.forDesktopMidUp {
    min-width: 444px;
    border-left: 1px solid var(--c-gray600);
  }
}
