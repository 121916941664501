@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.sliderWrapper {
  padding: 0 14px;
}

.sliderValueRoot {
  display: flex;
  margin-bottom: 10px;
}

.sliderValueItem {
  margin-right: 5px;
}

.defaultPrice {
  color: var(--c-gray800);
}

.priceBox {
  box-sizing: border-box;
  background-color: var(--c-white);
  border-radius: 4px;
  max-width: 128px;
  max-height: 48px;
  @include mq.forUpToTablet {
    max-width: 148px;
  }
}

.priceValueSection {
  margin-top: u.spacing(1);
}

.icon {
  margin-right: -4px;
}

.inputRoot {
  & fieldset {
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: var(--c-gray600);
    transition:
      border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.input {
  padding-left: u.spacing(1);
  padding-right: u.spacing(1);
  font-family: var(--body-font-family);
  font-size: 18px;
  &::placeholder {
    color: var(--c-gray800);
    opacity: 1;
  }
}
