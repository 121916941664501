@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/colors' as c;
@use 'ui-kit/theme/media' as mq;

.root {
  & :global(.MuiSlider-rail) {
    background-color: var(--c-gray600);
    height: 4px;
    border-radius: 2px;
    @include mq.forUpToTablet {
      height: 8px;
      border-radius: 4px;
    }
  }
  & :global(.MuiSlider-track) {
    background-color: var(--c-primaryBlue);
    height: 4px;
    border-radius: 2px;
    @include mq.forUpToTablet {
      height: 8px;
      border-radius: 4px;
    }
  }
  & :global(.MuiSlider-thumbColorPrimary) {
    background-color: var(--c-primaryBlue);
    @include mq.forDesktopSmallUp {
      width: 16px;
      height: 16px;
      &:hover {
        box-shadow: 0 0 0 u.spacing(0.5) rgba(c.$primaryBlue, 0.1);
      }
      &:focus {
        box-shadow: 0 0 0 u.spacing(0.5) rgba(c.$primaryBlue, 0.2);
      }
      &:global(.Mui-active) {
        box-shadow: 0 0 0 u.spacing(0.5) rgba(c.$primaryBlue, 0.4);
      }
    }
    @include mq.forUpToTablet {
      width: 24px;
      height: 24px;
      &:global(.Mui-active) {
        box-shadow: 0 0 0 u.spacing(1) rgba(c.$primaryBlue, 0.4);
      }
    }
  }
  &:global(.Mui-disabled) {
    filter: grayscale(1);
    opacity: 0.5;
  }
}

.valuesWrapper {
  margin: u.spacing(0, -1);
  @include mq.forUpToTablet {
    margin: u.spacing(0, -1.5);
  }
}
