@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

$PHONE_PADDING: 3; // 0-599
$TABLET_AND_HIGHER_PADDING: 5; // 600+

.root {
  width: 100%;
  box-sizing: border-box;
}

.withLeftPadding {
  padding-left: u.spacing($PHONE_PADDING);
  @include mq.forTabletUp {
    padding-left: u.spacing($TABLET_AND_HIGHER_PADDING);
  }
}

.withRightPadding {
  padding-right: u.spacing($PHONE_PADDING);
  @include mq.forTabletUp {
    padding-right: u.spacing($TABLET_AND_HIGHER_PADDING);
  }
}
