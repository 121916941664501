@use 'ui-kit/theme/units' as u;

.root {
  padding: u.spacing(1);
  &:hover path {
    fill: var(--c-primaryBlue);
  }
}

.colorSecondary {
  &:hover {
    background-color: var(--c-transparent);
  }
  &:hover span {
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(24, 60, 103, 0.28);
  }
}

.checked {
  &:hover {
    box-shadow: none;
  }
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
