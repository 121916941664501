@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.inputBase {
  box-sizing: border-box;
  font-size: 18px;
  height: 48px;
  padding: u.spacing(0, 1, 0, 1);
  background-color: var(--c-white);
  border-radius: 8px;
  border: 1px solid var(--c-gray600);
}

.inputFocused {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.focused {
  &:global(.Mui-focused) {
    border: 2px solid var(--c-primaryBlue);
  }
}

.primaryIconVariant {
  border-radius: 100px;
}

.withShadow {
  box-shadow: 0px 4px 16px rgba(23, 23, 23, 0.12);
}

.searchIcon {
  width: 32px;
  height: 32px;
  background-color: var(--c-gray400);
  border-radius: 100px;
  margin-right: 12px;
}

.searchIconBlue {
  background-color: var(--c-primaryBlue);
}

.input {
  padding: 15px 0;
  &:disabled {
    color: var(--c-black);
  }
}

.newInput {
  padding: u.spacing(0.5, 0.5, 0.5, 2);
  &:disabled {
    color: var(--c-black);
  }
}
