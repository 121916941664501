@use 'ui-kit/theme/units' as u;

.locationTitleRoot {
  margin-bottom: u.spacing(1);
}

.locationFilters {
  font-size: 16px;
  color: var(--c-gray800);
}

.locationSuggestion {
  color: var(--c-primaryBlue);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
