@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.paperRoot {
  box-sizing: border-box;
  padding: u.spacing(3);
  background-color: var(--c-white);
  border: 1px solid var(--c-gray500);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
  @include mq.forUpToTablet {
    padding: u.spacing(3, 2);
  }
}

.iconWrapper {
  height: 32px;
  @include mq.forUpToTablet {
    height: 24px;
  }
}
