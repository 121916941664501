@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.availableFilterWrapper {
  width: 100%;
  margin-top: u.spacing(3);
  @include mq.forUpToTablet {
    margin-top: u.spacing(2);
  }
}

.filterInfo {
  display: flex;
  gap: u.spacing(1);
  margin-bottom: u.spacing(1);
}
