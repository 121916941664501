.root {
}

.img {
  z-index: 2;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 1;
  transform-origin: 50%;
  animation: rotation 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid var(--c-primaryBlue);
  border-radius: 50%;
  border-top-color: transparent;
}
