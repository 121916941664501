@use 'ui-kit/theme/units' as u;

.root {
  max-width: 600px;
  box-sizing: border-box;
  max-height: 80vh;
  overflow-y: auto;
}

.withPaddings {
  padding: u.spacing(1);
}

.itemContent {
  display: flex;
  gap: u.spacing(2);
  align-items: flex-start;
}

.linkList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: u.spacing(2, 3);
  border-radius: 8px;
  & :global(.ps-svg-icon) [stroke] {
    stroke: var(--c-black);
  }
  &:hover {
    color: var(--c-white);
    background: var(--c-primaryBlue);
    & :global(.ps-svg-icon) [stroke] {
      stroke: var(--c-white);
    }
  }
}

.itemIcon {
  flex: 0 0 auto;
}

.itemIconPadding {
  padding-top: 3px;
}

.itemText {
  flex: 1 1 auto;
}

.link {
  width: 100%;
  color: currentcolor;
  &:hover {
    text-decoration: none;
  }
}
