@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  position: relative;
}

.swiper {
  position: relative;
  z-index: 1;
}

.fillEmptySpace {
  width: 100%;
  height: 100%;
  & > .swiper {
    width: 100%;
    height: 100%;
  }
}

.navigationButton {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  cursor: pointer;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background: var(--c-white);
  border: 1px solid var(--c-gray600);
  border-radius: 100px;
}

.prevButton {
  left: 16px;
  .bigNavigation > & {
    width: 40px;
    height: 40px;
    border: 1.25px solid var(--c-gray600);
  }
}

.nextButton {
  right: 16px;
  .bigNavigation > & {
    width: 40px;
    height: 40px;
    border: 1.25px solid var(--c-gray600);
  }
}

.disabledNavigation {
  opacity: 0.35;
  cursor: default !important;
  .hideInactiveNav > & {
    display: none;
  }
}

.hiddenNavigation {
  & > .navigationButton {
    display: none;
  }
}

.showOnHover {
  & > .navigationButton {
    display: none;
  }
  &:hover {
    @include mq.forDesktopSmallUp {
      & > .navigationButton:not(.disabledNavigation) {
        display: flex;
      }
    }
  }
}

.bigNavigation {
}

.hideInactiveNav {
}

.navigationOutsidePadded {
  padding-left: u.spacing(8);
  padding-right: u.spacing(8);
}

.navigationOutside {
  & .prevButton {
    left: -56px;
  }
  & .nextButton {
    right: -56px;
  }
}

.customPagination {
  & > :global(.swiper-pagination) {
    --swiper-theme-color: var(--c-white);
    --swiper-pagination-bullet-inactive-color: var(--c-white);
    --swiper-pagination-bullet-inactive-opacity: 0.5;
  }
}

.customPaginationSize {
  & > :global(.swiper-pagination) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 12px;
    box-sizing: border-box;
    --swiper-pagination-bullet-size: 4px;
    --swiper-pagination-bottom: 4px;
    @include mq.forPhone {
      --swiper-pagination-bottom: 32px;
    }
    & :global(.swiper-pagination-bullet) {
      transition:
        opacity 0.25s,
        height 0.25s,
        width 0.25s;
    }
    & :global(.swiper-pagination-bullet-active) {
      --swiper-pagination-bullet-size: 8px;
    }
  }
  & :global(.swiper-pagination-bullets):global(.swiper-pagination-horizontal) {
    width: 100%;
  }
}

.dynamicBulletsEnabled {
  & > :global(.swiper-pagination) {
    --swiper-pagination-bottom: 10px;
  }
}

.paginationVariantHero {
  & > :global(.swiper-pagination) {
    width: auto;
    left: 0;
    right: 0;
    bottom: 16px;
    top: auto;
    @include mq.forPhone {
      bottom: 32px;
      right: 50%;
    }
  }
}
