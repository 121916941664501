@use 'ui-kit/theme/units' as u;

.root {
  display: inline-block;
  & :global(.MuiButton-endIcon) {
    margin-left: u.spacing(0.5);
    margin-right: 0;
  }
}

.arrow {
  height: 29px;
  transition: transform 0.2s ease-out;
  transform: rotate(0deg);
}

.arrowHovered {
  transform: rotate(-180deg);
}
