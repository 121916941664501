@use 'ui-kit/theme/units' as u;

.root {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: u.spacing(1);
  display: inline-flex;
}

.text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}
