@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  width: 60%;
  @include mq.forUpToTablet {
    width: 100%;
  }
}

.metroCityWrapper {
  display: flex;
  gap: u.spacing(1);
}

.image {
  border-radius: 100px;
}

.imageContainer {
  margin-right: u.spacing(1);
  height: 40px;
}

.button {
  min-width: 180px;
  height: 48px;
}
