@use 'ui-kit/theme/units' as u;

.root {
  border-radius: 8px;
  color: var(--c-black);
  background: var(--c-white);
}

.withPaddings {
  padding: u.spacing(1);
}

.item {
  padding: u.spacing(2, 3);
  border-radius: 8px;
  &:hover {
    color: white;
    background: var(--c-primaryBlue);
  }
}

.linkList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.link {
  width: 100%;
  color: currentcolor;
  &:hover {
    text-decoration: none;
  }
}
