@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  align-items: center;
  padding: u.spacing(2);
  gap: u.spacing(2);
  background-color: var(--c-gray400);
  border-radius: 8px;
  @include mq.forUpToTablet {
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
}

.button {
  min-width: fit-content;
}
