// Be careful, this doesn't work properly on non-webkit browsers
// https://caniuse.com/css-line-clamp
@mixin multiline-ellipsis($lines-to-show) {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
