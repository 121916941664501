@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  display: flex;
  flex-direction: column;
  gap: u.spacing(1.5);
  margin-block: u.spacing(4);
}

.skeletonGroup {
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
  margin-top: u.spacing(1);
}
