@use 'ui-kit/theme/units' as u;

.root {
  color: var(--ps-link-accent-color);
}

.button {
  height: 100%;
  border: none;
  padding: 0;
  border-radius: 0;
  &:hover {
    text-shadow:
      0 0.015em currentColor,
      0 -0.015em currentColor,
      0.01em 0 currentColor,
      -0.01em 0 currentColor;
  }
}

.menuList {
  padding: 0;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 99px;
  position: relative;
  top: -4px;
  margin-left: u.spacing(0.5);
}

.isActive {
  & .button {
    box-shadow: inset 0 -4px 0 var(--c-primaryYellow);
    &:hover {
      text-shadow: none;
    }
  }
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}
