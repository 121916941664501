@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: u.spacing(2);
  gap: u.spacing(2);
  background-color: var(--c-gray300);
  border: 1px solid var(--c-gray600);
  border-radius: 8px;
  margin-top: u.spacing(2);
  margin-bottom: u.spacing(3);
}

.image {
  border-radius: 8px;
}

.shareButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: u.spacing(-1);
}

.shareButton {
  flex-grow: 0;
  flex-basis: 50%;
  max-width: 50%;
  padding: u.spacing(1);
}

.socialMediaShareButton {
  width: 100%;
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: calc(100% - 80px);
  @include mq.forUpToTablet {
    -webkit-line-clamp: 3;
  }
}

.button {
  min-width: max-content;
}
