@use 'ui-kit/theme/layers' as z;

.root {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.popup {
  box-shadow: 0px 4px 16px rgba(23, 23, 23, 0.06);
  border-radius: 4px;
}

.popupWrapper {
  position: absolute;
  right: var(--hover-menu-right);
  bottom: var(--hover-menu-bottom);
  top: var(--hover-menu-top);
  z-index: z.$speedDial;
  overflow: auto;
  max-height: calc(100vh - 72px);
}

.popupWrapperRight {
  right: 0;
}

.isCentered {
  left: 50%;
  transform: translateX(-50%);
}
