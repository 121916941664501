.root {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
}

.blurredSlideImage {
  filter: blur(5px);
}

.blurredSlideContainer {
  position: relative;
  height: 100%;
}

.blurredSlideButton {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
