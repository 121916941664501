@use 'ui-kit/theme/units' as u;

.root {
  margin-bottom: u.spacing(3);
}

.locationTitleRoot {
  margin-bottom: u.spacing(1);
}

.locationTitle {
  margin-left: 10px;
  font-size: 18px;
  color: var(--c-gray800);
}

.locationFilters {
  padding-left: 26px;
  font-size: 16px;
  color: var(--c-gray800);
}

.locationSuggestion {
  margin-left: 26px;
  padding: 2px 0;
  color: var(--c-primaryBlue);
  cursor: pointer;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}

.skeletonContainer {
  display: flex;
  width: '100%';
  flex-direction: row;
  gap: u.spacing(2);
  margin-block: u.spacing(2);
}

.skeletonGroup {
  display: flex;
  flex-direction: column;
  gap: u.spacing(2);
}
