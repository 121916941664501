@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/colors' as c;

.root {
  display: flex;
  gap: u.spacing(5);
}

.rootRight {
  align-items: center;
  text-align: center;
  @include mq.forUpToTablet {
    display: none;
  }

  .signUpButton:hover {
    background-color: fade-out(c.$white, 0.15);
  }
}

.navItemText {
  font-size: 16px;
  font-weight: normal;
  &:hover {
    text-decoration: none;
  }
}

.removeLeftPadding {
  padding-left: u.spacing(0);
}

.arrowRoot {
  margin-left: u.spacing(0);
}

.menuClass {
  background-color: var(--c-gray400);
  border: 1px solid var(--c-gray500);
  border-radius: 8px;
}

.paperPopup {
  margin-top: u.spacing(0.5);
  border-radius: 8px;
}

.roomsSearchField {
  width: 200px;
}
