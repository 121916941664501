@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.action {
  display: flex;
  gap: u.spacing(2);
  margin-top: u.spacing(8);
  @include mq.forUpToTablet {
    flex-direction: column-reverse;
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;
  }
}

.button {
  min-width: 180px;
  height: 48px;
}

.helperText {
  position: absolute;
  bottom: 160px;
}

.hideText {
  visibility: hidden;
}
