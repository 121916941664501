@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  width: 80%;
  @include mq.forUpToTablet {
    width: 100%;
    margin-bottom: u.spacing(8);
  }
}

.infoContainer {
  padding: u.spacing(2);
  background-color: var(--c-gray300);
  border-radius: 8px;
}

.iconWrapper {
  height: 32px;
  margin-right: u.spacing(2);
  @include mq.forUpToTablet {
    margin-right: u.spacing(1.5);
  }
}

.link {
  &:hover {
    cursor: pointer;
  }
}

.propertiesWrapper {
  margin-top: u.spacing(5);
  @include mq.forUpToTablet {
    margin-top: u.spacing(3);
  }
}

.availableFilterWrapper {
  width: 100%;
  margin-top: u.spacing(3);
  @include mq.forUpToTablet {
    margin-top: u.spacing(2);
  }
}

.button {
  height: 48px;
}

.buttonText {
  margin-left: u.spacing(1);
}

.stickyButton {
  width: 100%;
  z-index: 1;
  left: 0;
  bottom: 0;
  position: absolute;
  padding: u.spacing(2);
  background-color: var(--c-white);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.04);
}
