@use 'ui-kit/theme/units' as u;

.button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: u.spacing(0.75);
  border: 1px solid var(--c-gray600);
  border-radius: 4px;
  background-color: var(--c-white);
  &:hover {
    background-color: var(--c-mysticLightBlue);
  }
  &:active {
    background-color: #d5dde8;
  }
}
