@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/mixins/ellipsis' as e;

.main {
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: u.spacing(0.5, 0);
  @include mq.forUpToTablet {
    min-height: 48px;
  }
}

.mainCompact {
  min-height: 24px;
}

.compact {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.title {
  white-space: normal;
  @include e.multiline-ellipsis(2);
  @include mq.forUpToTablet {
    margin: 0;
  }
}
