@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/layers' as z;

.root {
  background-color: var(--c-transparent);
  position: relative;
}

.inputBase {
  box-sizing: border-box;
  font-size: 18px;
  height: 48px;
  padding: u.spacing(0, 1, 0, 1);
  background-color: var(--c-white);
  border-radius: 8px;
  border: 1px solid var(--c-gray600);
}

.primaryIconVariant {
  border-radius: 100px;
}

.withShadow {
  box-shadow: 0px 4px 16px rgba(23, 23, 23, 0.12);
}

.input {
  padding: 15px 0;
  &:disabled {
    color: var(--c-black);
  }
}

.iconWrapper {
  margin-right: u.spacing(1);
}

.paper {
  position: absolute;
  box-sizing: border-box;
  background-color: var(--c-white);
  border-radius: 0px 0px 8px 8px;
  padding: u.spacing(3);
  z-index: z.$speedDial;
  width: 100%;
  border: 1px solid var(--c-gray600);
  visibility: hidden;
  pointer-events: none;
}

.roundedPaper {
  border-radius: 16px;
  margin-top: u.spacing(1);
}

.paperBox {
  width: 100%;
  max-height: 530px;
  overflow: auto;
  // added to avoid horizontal scrollbar
  overflow-x: auto;
  visibility: hidden;
  pointer-events: none;
  scrollbar-color: var(--c-gray600) var(--c-transparent);
  scrollbar-width: thin;
}

.visible {
  visibility: visible;
  pointer-events: auto;
}

.noSuggestions {
  color: var(--c-inactiveRed);
  text-align: center;
  padding: 0;
  font-weight: bold;
}

.availableLocationsTitle {
  margin-bottom: 20px;
}

.dialogInput {
  flex: 1;
}

.dialogCloseButton {
  padding: 0;
}

.dialogContent {
  padding: 24px 0 50px;
}

.box {
  padding: u.spacing(3);
}

.searchIcon {
  width: 32px;
  height: 32px;
  background-color: var(--c-gray400);
  border-radius: 100px;
  margin-right: 12px;
}
