@use 'ui-kit/theme/units' as u;

.root {
}

.subMenuItemWrapper {
  &:hover {
    color: var(--c-primaryBlue);
    background: var(--c-gray400);
    & :global(.ps-svg-icon--dynamic) {
      & path,
      & rect,
      & line {
        stroke: var(--c-primaryBlue);
      }
    }
    & .subMenuIcon > svg > path {
      color: var(--c-primaryBlue);
    }
  }
}

.subMenuItem {
  padding: u.spacing(2);
  display: flex;
  gap: u.spacing(2);
}

.subMenuItemContent {
  display: flex;
  flex-direction: column;
  gap: u.spacing(1);
  &:hover {
    color: var(--c-primaryBlue);
    background: var(--c-gray400);
  }
}
