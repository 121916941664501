@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/layers' as z;

$DEFAULT_APP_BAR_HEIGHT: 72px;
$SMALL_APP_BAR_HEIGHT: 62px;

.root {
  height: $SMALL_APP_BAR_HEIGHT;
  background-color: var(--base-app-bar-background-color);
  position: sticky;
  z-index: z.$appBar;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  @include mq.forDesktopSmallUp {
    height: $DEFAULT_APP_BAR_HEIGHT;
  }
}
