@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;

.root {
  max-width: 736px;
}

.subTitle {
  margin-top: u.spacing(2);
  @include mq.forUpToTablet {
    margin-top: u.spacing(1);
  }
}

.popularCity {
  margin-bottom: u.spacing(3);
}

.loaderWrapper {
  margin: auto;
}
