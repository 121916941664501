@use 'ui-kit/theme/units' as u;
@use 'ui-kit/theme/media' as mq;
@use 'ui-kit/theme/layers' as z;

.root {
  border-radius: 8px;
}

.images {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: inherit;
  z-index: 0;
}

.topContentWithCompact {
  aspect-ratio: 1.5;
}

.bottomContent {
  padding: u.spacing(2);
}

.compactBottomContent {
  border-top: 1px solid var(--c-gray600);
}

.imagesWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.imagesWrapperOccupied {
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--c-black);
    opacity: 0.5;
    pointer-events: none;
    z-index: 1;
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: u.spacing(0.5);
  color: var(--c-black);
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 27px;
  @include mq.forUpToTablet {
    font-size: 16px;
    line-height: 24px;
  }
}

.compactInfo {
  color: var(--c-black);
  font-size: 16px;
  line-height: 24px;
  @include mq.forUpToTablet {
    font-size: 14px;
    line-height: 24px;
  }
}

.price {
  color: var(--c-black);
}

.priceDrop {
  color: var(--c-activeGreen);
}

.priceBeforeDrop {
  color: var(--c-gray800);
  text-decoration: line-through;
  margin-right: u.spacing(0.5);
  font-weight: normal;
}

.hostOccupiedBox {
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: var(--c-white);
  padding: u.spacing(0.5, 1);
  z-index: 100;
}

.rating {
  display: flex;
  align-items: center;
}

.ratingLabel {
  color: var(--c-black);
  padding-left: u.spacing(1);
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  @include mq.forUpToTablet {
    font-size: 14px;
    line-height: 18px;
  }
}

.ratingLabelPositive {
  color: var(--c-activeGreen);
}

.labels {
  position: absolute;
  z-index: z.$mobileStepper;
  left: 8px;
  top: 8px;
  @include mq.forUpToTablet {
    position: absolute;
    display: block;
  }
}

.compactLabels {
  @include mq.forUpToTablet {
    position: static;
  }
}

.hiddenUpToTablet {
  @include mq.forUpToTablet {
    display: none;
  }
}

.compactCommute {
  display: flex;
  border-top: none;
  padding: u.spacing(0.5, 2);
}

.rightBox {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: z.$mobileStepper;
}

.rightBoxCompactMobile {
  right: 0;
  left: 8px;
}

.imageOverlay {
  z-index: z.$actionButton;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  padding: u.spacing(1);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--c-black);
    opacity: 0.75;
    z-index: -1;
  }
}

.shareButtonWrapper {
  right: 50px;
}

.approvedAmount {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
}

.listingLabel {
  display: flex;
  padding: u.spacing(0.5, 1);
  width: fit-content;
  height: 32px;
  align-items: center;
  gap: u.spacing(0.5);
  border: 1px solid var(--c-gray600);
  border-radius: 4px;
  background-color: var(--c-white);
}

.commuteLabelMargin {
  margin: u.spacing(0.5, 0);
  padding: u.spacing(0.25, 1);
  background-color: var(--c-gray300);
}

.labelWrapper {
  display: flex;
  justify-content: space-between;
}

.inline {
  display: flex;
  gap: u.spacing(0.5);
  container-type: inline-size;
}

.wrap {
  flex-wrap: wrap;
}

.neighborhoodTittle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.neighborhoodOffsets {
  padding: u.spacing(0, 0.5);
}

.hideDot {
  display: none;
  @container (min-width: 290px) {
    display: block;
  }
}

.priorityRoom {
  background-color: var(--c-neutralLightBlue);
  border-radius: 4px;
  padding: u.spacing(0, 1);
}

.roomLeft {
  text-wrap: nowrap;
  min-width: fit-content;
}

.cursorPointer {
  cursor: pointer;
}

.footerInlineLabel {
  background-color: var(--c-pendingYellow);
  padding: u.spacing(0, 0.5);
  border-radius: u.spacing(0.5);
  margin-left: u.spacing(1);
  min-width: fit-content;
}

.labelsWrapper {
  display: flex;
  gap: u.spacing(0.5);
}
